import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TopBanner from './TopBanner';

import Legals from './panels/Legals/Legals';
import Account from './panels/Account/Account';
import Degrees from './panels/Degrees/Degrees';
import Final from './panels/Final';
import { connect } from 'react-redux';
import { getMe } from '@/stores/users';
import { check } from '@/stores/me';
import { addToGTM } from '@components/molecules/gtm';

import './index.css';

const Onboarding = ({
  getMe,
  check,
}) => {
  useEffect(() => {
    if (getMe) {
      getMe();
    }
  }, [getMe]);

  const [displayedSection, updateDisplayedSection] = useState(0);

  const goToNextSection = () => {
    updateDisplayedSection((prevState) => prevState + 1);
  };

  const closeOnboarding = () => {
    addToGTM({
      event: 'onboardingValidation',
    });
    check();
  };

  return (
    <div className='onboarding'>
      <TopBanner displayedSection={displayedSection} />
      {displayedSection < 2 && (
        <React.Fragment>
          <div
            className={`onboarding__inner pg-1 bg-white shadow bd-radius flex layout-column ${
              displayedSection && 'active'
            }`}
          >
            <div
              className='onboarding__title__scroll mgb-1'
              data-section={displayedSection}
            >
              <h2 className='onboarding__title no-margin flex align-start-center'>
                <div className='w-4 h-4 pg-0_25 bd-radius bg-gray-lighter flex align-center-center mgr-0_75'>
                  <svg>
                    <use xlinkHref='#icon-note-3' />
                  </svg>
                </div>
                Termes et conditions
              </h2>
              <h2 className='onboarding__title no-margin flex align-start-center'>
                <div className='w-4 h-4 pg-0_25 bd-radius bg-primary text-white flex align-center-center mgr-0_75'>
                  <svg>
                    <use xlinkHref='#icon-account' />
                  </svg>
                </div>
                Compte utilisateur
              </h2>
            </div>
            <div className='pgt-1 bdt-1-gray-light'></div>
            <div className='onboarding__slider' data-section={displayedSection}>
              {[Legals, Account, Degrees].map((Component, index) => (
                <div className='onboarding__panel' key={index.toString()}>
                  <Component goToNextSection={() => goToNextSection()} />
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
      {displayedSection > 1 && <Final closeOnboarding={closeOnboarding} />}
    </div>
  );
};

Onboarding.propTypes = {
  goToNextSection: PropTypes.func,
  closeOnboarding: PropTypes.func,
  displayedSection: PropTypes.number,
  getMe: PropTypes.any,
  check: PropTypes.any,

};

export default connect(null, { getMe, check })(Onboarding);
