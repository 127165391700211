let envs = {
  default: {
    apiUrl: process.env.REACT_APP_API_URL,
    GTM: {
      code: '',
    },
    debug: false,
    payment: {
      salt1: process.env.REACT_APP_PAYMENT_SALT_1,
      salt2: process.env.REACT_APP_PAYMENT_SALT_2,
      url: process.env.REACT_APP_PAYMENT_URL,
    },
    IdleUserTimeout: 180000,
    DefaultEventsTracking: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll'],
    limitConnexionAmount: 3,
    TimeToSendData: 600,
    TimeToSendDataActivity: 1000,
    inactivityTime: 1000 * 3600 * 60,
    expirationDateSchoolReport: 'June 12, 2020 17:15:00',
    homework: {
      acceptedFiles: [
        'pdf',
        'doc',
        'docx',
        'png',
        'rtf',
        'odt',
        'jpg',
        'jpeg',
        'avi',
        'mp3',
        'mp4',
        'mov',
      ],
      sendingErrorEmail: process.env.REACT_APP_SENDING_ERROR_EMAIL,
      sentMessageDuration: 1000 * 15, //15m
    },
    examinations: {
      acceptedFiles: ['pdf', 'doc', 'docx', 'png', 'rtf', 'odt', 'jpg'],
      takingYearsRange: 4,
    },
    miscellaneous: {
      cenefHours: 'lundi au jeudi de 8h30 à 19h et le vendredi de 8h30 à 18h00',
      cenefPhone: '+33 (0)3 66 06 02 01',
      contactEmail: 'contact@ecole109.fr',
    },
    chat: {
      acceptedFiles: ['pdf', 'jpg', 'jpeg', 'png', 'xls', 'csv', 'doc', 'txt'],
      sizeMaxAttachment : 2000000,
      sizeMaxAttachmentString : '2 Mo'
    },
    profilAvatar : {
      acceptedFiles: ['jpg', 'jpeg', 'png'],
      sizeMaxAttachment : 2000000,
      sizeMaxAttachmentString : '2 Mo'
    }
  }
};

export default envs[process.env.REACT_APP_HOST_ENV || process.env.NODE_ENV];