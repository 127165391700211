import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import fr from '@/lang/fr.js';

import Popin from '@components/atoms/popin';
import { logout } from '@/stores/auth.js';
import Item from './Item';
import Chip from '../../../atoms/chip/Chip';
import { connect } from 'react-redux';

const Menu = ({
  me,
  onOpenOfferPopin,
  logout,
  onOfferPopinRef,
  submitOffer,
  selectedOffer,
  shouldRedirect,
  isProspect,
  isFreeAccount,
  onToggleMenuClick,
  openMenu,
  onToggleAccountAccordionClick,
  openAccountAccordion,
  onToggleProfileAccordionClick,
  openProfileAccordion,
}) => {
  const [showChip, setShowChip] = useState();
  const [showChipResources, setShowChipResources] = useState();
  const [isDiscount, setIsDiscount] = useState(false);

  const showExaminations = !!(
    me?.selectedOffer?.domainCategory === 1 || me?.selectedOffer?.hasExamination
  );

  useEffect(() => {
    setShowChip(me?.selectedOffer?.onboardingAgenda);
    setShowChipResources(me?.selectedOffer?.hasNewResources);
    me?.selectedOffer &&
    me?.selectedOffer?.offerVersion &&
    me?.selectedOffer?.offerVersion.toLowerCase() === 'discount' &&
    setIsDiscount(true);
  }, [me]);

  useEffect(() => {
    if (me && me.selectedOffer && me.selectedOffer.offerVersion && me.selectedOffer.offerVersion.toLowerCase() === 'discount')
      setIsDiscount(true);
  }, [me]);

  return shouldRedirect ? (
    <Redirect push={true} to={{ pathname: '/' }} />
  ) : (
    <React.Fragment>
      <div
        className='mgr-1 header__profile__trigger'
        onClick={onToggleMenuClick}
      >
        <div
          className={`header__profile__trigger__icon w-4 h-4 anim-all ${
            openMenu && 'open'
          }`}
        >
          <span className='icon icon-open'>
            <svg className='text-white w-4 h-4'>
              <use xlinkHref='#icon-menu' />
            </svg>
          </span>
          <span className='icon icon-close sm-flex lg-dp-none'>
            <svg className='text-white w-4 h-4 '>
              <use xlinkHref='#icon-close' />
            </svg>
          </span>
        </div>
        {(showChip || showChipResources) && <Chip onMenuToggle />}
      </div>
      <div className={`header__profile__submenu ${openMenu && 'open'}`}>
        <h3 className='no-margin mgb-0_25 pgh-1 pgt-1'>
          {me?.selectedOffer?.domainName}
        </h3>
        {me?.offers?.length > 1 && (
          <p className='small no-margin pgh-1'>
            <a
              onClick={onOpenOfferPopin}
              className='text-primary h-text-primary-dark'
            >
              Changer de formation
            </a>
          </p>
        )}
        <hr />
        <Item
          path={`/${fr.route.courses}`}
          text='Mes cours'
          onItemClick={onToggleMenuClick}
        />
        <div
          className='flex layout-row'
          style={{ alignItems: 'center', flexWrap: 'nowrap' }}
        >
          <Item
            path={`/${fr.route.agenda}`}
            text='Mon agenda'
            onItemClick={onToggleMenuClick}
          />
          {showChip && <Chip icon />}
        </div>
        <Item
          path={`/${fr.route.videoconferences}`}
          text='Mes MasterClass'
          onItemClick={onToggleMenuClick}
        />
        <Item
          path={`/${fr.route.report}`}
          text='Mes évaluations'
          onItemClick={onToggleMenuClick}
        />
        <div
          className='row'
          style={{alignItems: 'center', flexWrap: 'nowrap'}}
        >
          {me?.selectedOffer?.hasLivret === true && (
            <Item
              path={`/${fr.route.schoolReport}`}
              text='Mon livret scolaire'
              onItemClick={onToggleMenuClick}
            />
          )}
        </div>
        <React.Fragment>
          {!isFreeAccount && (
            <div
              className="flex layout-row"
              style={{alignItems: 'center', flexWrap: 'nowrap'}}
            >
              {me?.selectedOffer?.hasResources && (
                <>
                  <Item
                    path={`/${fr.route.ressources}`}
                    text="Mes ressources"
                    onItemClick={onToggleMenuClick}
                  />
                  {showChipResources && <Chip icon/>}
                </>
              )}
            </div>)}
        </React.Fragment>
        <div
          className='flex align-spacebetween-center menu--item curp'
          onClick={onToggleAccountAccordionClick}
        >
          <span className='mgr-1'>Mon compte</span>
          <svg
            className={`text-gray-dark w-2 h-2 anim-all ${
              openAccountAccordion && 'rotate-180'
            }`}
          >
            <use xlinkHref='#icon-chevron' />
          </svg>
        </div>
        <div
          className={`header__profile__submenu__account ${
            openAccountAccordion && 'open'
          }`}
        >
          {!isProspect &&
          <>
            <Item
              path={`/${fr.route.profile_account}/${fr.route.account_rules}`}
              text='Mon règlement intérieur'
              onItemClick={onToggleMenuClick}
            />
            <Item
              path={`/${fr.route.profile_account}/${fr.route.account_cgu}`}
              text="Mes CGU"
              onItemClick={onToggleMenuClick}
            /></>}
          <Item
            path={`/${fr.route.profile_account}/${fr.route.account_contract}`}
            text='Mes contrats'
            onItemClick={onToggleMenuClick}
          />
          <Item
            path={`/${fr.route.profile_account}/${fr.route.profile_payment}`}
            text='Mes paiements'
            onItemClick={onToggleMenuClick}
          />
          {me?.selectedOffer?.invoice && (
            <Item
              path={`/${fr.route.profile_account}/${fr.route.account_bills}`}
              text='Mes factures'
              onItemClick={onToggleMenuClick}
            />
          )}
        </div>
        <div
          className='flex align-spacebetween-center menu--item curp'
          onClick={onToggleProfileAccordionClick}
        >
          <span className='mgr-1'>Mon profil</span>
          <svg
            className={`text-gray-dark w-2 h-2 anim-all ${
              openProfileAccordion && 'rotate-180'
            }`}
          >
            <use xlinkHref='#icon-chevron' />
          </svg>
        </div>
        <div
          className={`header__profile__submenu__account ${
            openProfileAccordion && 'open'
          }`}
        >
          <Item
            path={`/${fr.route.profile}`}
            text='Mes informations personnelles'
            onItemClick={onToggleMenuClick}
          />
          <Item
            path={`/${fr.route.profile}/${fr.route.profile_ids}`}
            text='Mes identifiants'
            onItemClick={onToggleMenuClick}
          />
          <Item
            path={`/${fr.route.profile}/${fr.route.profile_documents}`}
            text='Mes certificats'
            onItemClick={onToggleMenuClick}
          />
        </div>
        <Item
          path={`/${fr.route.faq}`}
          text='FAQ '
          onItemClick={onToggleMenuClick}
        />
        <a href='' className='menu--logout' onClick={() => logout()}>
          Se déconnecter
        </a>
      </div>
      {me?.offers?.length > 1 && (
        <Popin forceOpen={false} onRef={onOfferPopinRef} width={500}>
          <h2 className='text-primary-dark fw-36'>Changer de formation</h2>
          <form
            onSubmit={submitOffer}
            className='flex layout-column align-start-start size-100p'
          >
            <select
              name='new-offer'
              className='size-100p mgb-1'
              defaultValue={selectedOffer || me?.selectedOffer?.id}
            >
              {me?.offers.map((offer, offerIndex) => (
                <option key={offerIndex.toString()} value={offer.id}>
                  {offer.domainName}&nbsp;{offer.offerName}
                </option>
              ))}
            </select>
            <button type='submit' className='button primary'>
              Valider
            </button>
          </form>
        </Popin>
      )}
    </React.Fragment>
  );
};

Menu.propTypes = {
  me: PropTypes.object,
  logout: PropTypes.func,
  onOfferPopinRef: PropTypes.func,
  onOpenOfferPopin: PropTypes.func,
  submitOffer: PropTypes.func,
  selectedOffer: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  isProspect: PropTypes.bool,
  isFreeAccount: PropTypes.bool,
  onToggleMenuClick: PropTypes.func,
  openMenu: PropTypes.bool,
  onToggleAccountAccordionClick: PropTypes.func,
  openAccountAccordion: PropTypes.bool,
  onToggleProfileAccordionClick: PropTypes.func,
  openProfileAccordion: PropTypes.bool,
};

export default connect(null, { logout })(Menu);
