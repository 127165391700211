import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_RESSOURCES_REQUESTED = 'ressources/GET_RESSOURCES_REQUESTED';
export const GET_RESSOURCES_SUCCESSFUL = 'ressources/GET_RESSOURCES_SUCCESSFUL';
export const GET_RESSOURCES_FAILED = 'ressources/GET_RESSOURCES_FAILED';

export const RESSOURCES_DOWNLOAD_REQUESTED =
  'ressources/RESSOURCES_DOWNLOAD_REQUESTED';
export const RESSOURCES_DOWNLOAD_SUCCESSFUL =
  'ressources/RESSOURCES_DOWNLOAD_SUCCESSFUL';
export const RESSOURCES_DOWNLOAD_FAILED =
  'ressources/RESSOURCES_DOWNLOAD_FAILED';

const initialState = {
  documents: [],
  download: { file: null, name: null },
};

export default function Ressources(state = initialState, action) {
  switch (action.type) {
    case GET_RESSOURCES_SUCCESSFUL:
      return {
        ...state,
        documents: action.ressources,
      };
    case GET_RESSOURCES_FAILED:
      return initialState;
    case RESSOURCES_DOWNLOAD_SUCCESSFUL:
      return {
        ...state,
        download: { file: action.download.file, name: action.download.name },
      };
    case RESSOURCES_DOWNLOAD_FAILED:
      return state;
    default:
      return state;
  }
}

export const downloadDocument = (document) => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: RESSOURCES_DOWNLOAD_REQUESTED });
  return Api.get(document.url, { responseType: 'blob' })
    .then((res) => {
      const file = new Blob([res.data], { type: res.headers['content-type'] });
      dispatch({
        type: RESSOURCES_DOWNLOAD_SUCCESSFUL,
        download: { file: file, name: document.name },
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch(() => {
      dispatch({ type: RESSOURCES_DOWNLOAD_FAILED });
      dispatch({ type: ISNT_LOADING });
    });
};

export const getRessources = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_RESSOURCES_REQUESTED });

  return Api('/ressources', { params: params })
    .then((res) => {
      dispatch({
        type: GET_RESSOURCES_SUCCESSFUL,
        ressources: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_RESSOURCES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};
