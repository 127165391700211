import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';

export const GET_TOPICS_REQUESTED = 'chat/GET_TOPICS_REQUESTED';
export const GET_TOPICS_SUCCESSFUL = 'chat/GET_TOPICS_SUCCESSFUL';
export const GET_TOPICS_FAILED = 'chat/GET_TOPICS_FAILED';

export const GET_TOPIC_REQUESTED = 'chat/GET_TOPIC_REQUESTED';
export const GET_TOPIC_SUCCESSFUL = 'chat/GET_TOPIC_SUCCESSFUL';
export const GET_TOPIC_FAILED = 'chat/GET_TOPIC_FAILED';

export const GET_SUBJECTS_REQUESTED = 'chat/GET_SUBJECTS_REQUESTED';
export const GET_SUBJECTS_SUCCESSFUL = 'chat/GET_SUBJECTS_SUCCESSFUL';
export const GET_SUBJECTS_FAILED = 'chat/GET_SUBJECTS_FAILED';

export const GET_CHILDREN_SUBJECTS_REQUESTED = 'chat/GET_CHILDREN_SUBJECTS_REQUESTED';
export const GET_CHILDREN_SUBJECTS_SUCCESSFUL = 'chat/GET_CHILDREN_SUBJECTS_SUCCESSFUL';
export const GET_CHILDREN_SUBJECTS_FAILED = 'chat/GET_CHILDREN_SUBJECTS_FAILED';

export const POST_TOPIC_REQUESTED = 'chat/POST_TOPIC_REQUESTED';
export const POST_TOPIC_SUCCESSFUL = 'chat/POST_TOPIC_SUCCESSFUL';
export const POST_TOPIC_FAILED = 'chat/POST_TOPIC_FAILED';

export const POST_ANSWER_REQUESTED = 'chat/POST_ANSWER_REQUESTED';
export const POST_ANSWER_SUCCESSFUL = 'chat/POST_ANSWER_SUCCESSFUL';
export const POST_ANSWER_FAILED = 'chat/POST_ANSWER_FAILED';

export const ARCHIVE_TOPIC_REQUESTED = 'chat/ARCHIVE_TOPIC_REQUESTED';
export const ARCHIVE_TOPIC_SUCCESSFUL = 'chat/ARCHIVE_TOPIC_SUCCESSFUL';
export const ARCHIVE_TOPIC_FAILED = 'chat/ARCHIVE_TOPIC_FAILED';


export const BLOCK_TOPIC_REQUESTED = 'chat/BLOCK_TOPIC_REQUESTED';
export const BLOCK_TOPIC_SUCCESSFUL = 'chat/BLOCK_TOPIC_SUCCESSFUL';
export const BLOCK_TOPIC_FAILED = 'chat/BLOCK_TOPIC_FAILED';

export const UNBLOCK_TOPIC_REQUESTED = 'chat/UNBLOCK_TOPIC_REQUESTED';
export const UNBLOCK_TOPIC_SUCCESSFUL = 'chat/UNBLOCK_TOPIC_SUCCESSFUL';
export const UNBLOCK_TOPIC_FAILED = 'chat/UNBLOCK_TOPIC_FAILED';

export const SIGNAL_TOPIC_REQUESTED = 'chat/SIGNAL_TOPIC_REQUESTED';
export const SIGNAL_TOPIC_SUCCESSFUL = 'chat/SIGNAL_TOPIC_SUCCESSFUL';
export const SIGNAL_TOPIC_FAILED = 'chat/SIGNAL_TOPIC_FAILED';

export const GET_STUDENTS_REQUESTED = 'chat/GET_STUDENTS_REQUESTED';
export const GET_STUDENTS_SUCCESSFUL = 'chat/GET_STUDENTS_SUCCESSFUL';
export const GET_STUDENTS_FAILED = 'chat/GET_STUDENTS_FAILED';

export const GET_STUDENT_REQUESTED = 'chat/GET_STUDENT_REQUESTED';
export const GET_STUDENT_SUCCESSFUL = 'chat/GET_STUDENT_SUCCESSFUL';
export const GET_STUDENT_FAILED = 'chat/GET_STUDENT_FAILED';

export const GET_TOPIC_COURSES_REQUESTED = 'chat/GET_TOPIC_COURSES_REQUESTED';
export const GET_TOPIC_COURSES_SUCCESSFUL = 'chat/GET_TOPIC_COURSES_SUCCESSFUL';
export const GET_TOPIC_COURSES_FAILED = 'chat/GET_TOPIC_COURSES_FAILED';

export const RESET_SUBJECTS = 'chat/RESET_SUBJECTS';

const initialState = {
  topics: [],
  topic: {},
  subjects: [],
  topicIsPosted: undefined,
  topicIsBlocked: undefined,
  topicIsUnblocked: undefined,
  topicIsSignaled: undefined,
  students: [],
  student: {},
};

export default function Chat(state = initialState, action) {
  switch (action.type) {
    case GET_TOPICS_REQUESTED:
      return {
        ...state,
      };
    case GET_TOPICS_SUCCESSFUL:
      return {
        ...state,
        topics: action.topics,
      };
    case GET_TOPICS_FAILED:
      return {
        ...state,
      };

    case GET_TOPIC_REQUESTED:
      return {
        ...state,
      };
    case GET_TOPIC_SUCCESSFUL:
      return {
        ...state,
        topic: action.topic,
      };
    case GET_TOPIC_FAILED:
      return {
        ...state,
      };
    case GET_TOPIC_COURSES_SUCCESSFUL:
      return {
        ...state,
        topicCourses: action.topicCourses,
      };
    case GET_SUBJECTS_REQUESTED:
      return {
        ...state,
      };
    case GET_SUBJECTS_SUCCESSFUL:
      return {
        ...state,
        subjects: action.subjects,
      };
    case GET_SUBJECTS_FAILED:
      return {
        ...state,
      };

    case GET_CHILDREN_SUBJECTS_REQUESTED:
      return {
        ...state,
      };
    case GET_CHILDREN_SUBJECTS_SUCCESSFUL:
      return {
        ...state,
        childrenSubjects: action.subjects,
      };
    case GET_CHILDREN_SUBJECTS_FAILED:
      return {
        ...state,
      };

    case POST_TOPIC_REQUESTED:
      return {
        ...state,
      };
    case POST_TOPIC_SUCCESSFUL:
      return {
        ...state,
        topicIsPosted: action.topicIsPosted,
        topicIsSignaled: undefined,
        topicIsBlocked: undefined,
        topicIsUnblocked: undefined,
      };
    case POST_TOPIC_FAILED:
      return {
        ...state,
        topicIsPosted: action.topicIsPosted,
      };
    case POST_ANSWER_SUCCESSFUL:
      return {
        ...state,
        topicIsPosted: action.topicIsPosted,
        topicIsSignaled: undefined,
        topicIsBlocked: undefined,
        topicIsUnblocked: undefined,
      };
    case POST_ANSWER_FAILED:
      return {
        ...state,
        topicIsPosted: action.topicIsPosted,
      };

    case BLOCK_TOPIC_REQUESTED:
      return {
        ...state,
      };
    case BLOCK_TOPIC_SUCCESSFUL:
      return {
        ...state,
        topicIsBlocked: action.topicIsBlocked,
        topicIsSignaled: undefined,
        topicIsUnblocked: undefined,
      };
    case BLOCK_TOPIC_FAILED:
      return {
        ...state,
        topicIsBlocked: action.topicIsBlocked,
      };

    case UNBLOCK_TOPIC_REQUESTED:
      return {
        ...state,
      };
    case UNBLOCK_TOPIC_SUCCESSFUL:
      return {
        ...state,
        topicIsUnblocked: action.topicIsUnblocked,
        topicIsSignaled: undefined,
        topicIsBlocked: undefined,
      };
    case UNBLOCK_TOPIC_FAILED:
      return {
        ...state,
        topicIsUnblocked: action.topicIsUnblocked,
      };

    case SIGNAL_TOPIC_REQUESTED:
      return {
        ...state,
      };
    case SIGNAL_TOPIC_SUCCESSFUL:
      return {
        ...state,
        topicIsSignaled: action.topicIsSignaled,
        topicIsBlocked: undefined,
        topicIsUnblocked: undefined,
      };
    case SIGNAL_TOPIC_FAILED:
      return {
        ...state,
        topicIsSignaled: action.topicIsSignaled,
      };

    case GET_STUDENTS_REQUESTED:
      return {
        ...state,
      };
    case GET_STUDENTS_SUCCESSFUL:
      return {
        ...state,
        students: action.students,
      };
    case GET_STUDENTS_FAILED:
      return {
        ...state,
      };

    case GET_STUDENT_REQUESTED:
      return {
        ...state,
      };
    case GET_STUDENT_SUCCESSFUL:
      return {
        ...state,
        student: action.student,
      };
    case GET_STUDENT_FAILED:
      return {
        ...state,
      };
    case ARCHIVE_TOPIC_SUCCESSFUL:
      return {
        ...state,
        topicIsSignaled: undefined,
        topicIsBlocked: undefined,
        topicIsUnblocked: undefined,
      };
    case ARCHIVE_TOPIC_FAILED:
      return {
        ...state,
      };

    case RESET_SUBJECTS :
      return {
        ...state,
        childrenSubjects: null,
      };
    default:
      return state;
  }
}

export const getTopics = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_TOPICS_REQUESTED });

  return Api('/topics', { params: params })
    .then((res) => {
      dispatch({
        type: GET_TOPICS_SUCCESSFUL,
        topics: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_TOPICS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getTopic = (topicId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_TOPIC_REQUESTED });

  if (topicId) {
    return Api(`/topics/${topicId}`, { params: params })
      .then((res) => {
        dispatch({
          type: GET_TOPIC_SUCCESSFUL,
          topic: res.data,
        });
        dispatch({ type: ISNT_LOADING });
      })
      .catch((err) => {
        dispatch({ type: GET_TOPIC_FAILED });
        if (err?.response?.status !== 401) {
          dispatch({
            type: SET_ERROR,
            value: err,
          });
        }
        dispatch({ type: ISNT_LOADING });
      });
  } else {
    dispatch({
      type: GET_TOPIC_SUCCESSFUL,
      topic: {},
    });
    return dispatch({ type: ISNT_LOADING });
  }
};

export const getSubjects = () => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_SUBJECTS_REQUESTED });

  return Api('/topics/subjects', { params: params })
    .then((res) => {
      dispatch({
        type: GET_SUBJECTS_SUCCESSFUL,
        subjects: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_SUBJECTS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getChildrenSubjects = (id) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_CHILDREN_SUBJECTS_REQUESTED });

  return Api( `/topics/subjects/${id}`, { params: params })
    .then((res) => {
      dispatch({
        type: GET_CHILDREN_SUBJECTS_SUCCESSFUL,
        subjects: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_CHILDREN_SUBJECTS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getTopicCourses = () => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_TOPIC_COURSES_REQUESTED });

  return Api.get('/topics/subjects/courses', { params: params })
    .then((res) => {
      dispatch({
        type: GET_TOPIC_COURSES_SUCCESSFUL,
        topicCourses: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: POST_TOPIC_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const postTopic =
  ( subject, to, courseId, text, toIsStudent, files ) =>
    async (dispatch) => {
      dispatch({ type: RESET_ERROR });
      dispatch({ type: IS_LOADING });
      dispatch({ type: POST_TOPIC_REQUESTED });
      let formData = new FormData();
      let objetContent = {
        subject: subject,
        toIsStudent: toIsStudent,
        to: to,
        text: text,
        courseId: courseId
      };
      let jsonString = JSON.stringify(objetContent);
      to && formData.append('content', jsonString );
      files && files.map(file =>(
        formData.append(file.name , file.file)
      ));

      return Api.post(
        '/topics',
        formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: params,
        })
        .then((res) => {
          dispatch({
            type: POST_TOPIC_SUCCESSFUL,
            topicIsPosted: true,
          });
          dispatch({ type: ISNT_LOADING });
        })
        .catch((err) => {
          dispatch({
            type: POST_TOPIC_FAILED,
            topicIsPosted: false,
          });
          dispatch({
            type: SET_ERROR,
            value: err,
          });
          dispatch({ type: ISNT_LOADING });
        });
    };

export const postAnswer = (topicId, text, files) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: POST_ANSWER_REQUESTED });

  let formData = new FormData();
  let objetContent = {
    text: text
  };
  let jsonString = JSON.stringify(objetContent);
  topicId && formData.append('content', jsonString );
  files && files.map(file =>(
    formData.append(file.name , file.file)
  ));

  return Api.post(`/topics/${topicId}`,
    formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: params,
    })
    .then((res) => {
      dispatch({
        type: POST_ANSWER_SUCCESSFUL,
        topicIsPosted: true,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: POST_ANSWER_FAILED,
        topicIsPosted: false,
      });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const blockTopic = (topicId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: BLOCK_TOPIC_REQUESTED });

  return Api.put(`/topics/block/${topicId}`, { params: params })
    .then((res) => {
      dispatch({
        type: BLOCK_TOPIC_SUCCESSFUL,
        topicIsBlocked: true,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: BLOCK_TOPIC_FAILED,
        topicIsBlocked: false,
      });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const archiveTopic = (arrayOfTopicID, archived) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: ARCHIVE_TOPIC_REQUESTED });

  return Api.put('/topics/archive',
    {id: arrayOfTopicID, archived: archived},
    {params: params})
    .then((res) => {
      dispatch({
        type: ARCHIVE_TOPIC_SUCCESSFUL,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: ARCHIVE_TOPIC_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const unblockTopic = (topicId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: UNBLOCK_TOPIC_REQUESTED });

  return Api.put(`/topics/unblock/${topicId}`, { params: params })
    .then((res) => {
      dispatch({
        type: UNBLOCK_TOPIC_SUCCESSFUL,
        topicIsUnblocked: true,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: UNBLOCK_TOPIC_FAILED,
        topicIsUnblocked: false,
      });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const signalTopic = (topicId, message) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: SIGNAL_TOPIC_REQUESTED });

  return Api.post(
    `/topics/signal/${topicId}`,
    { message: message },
    { params: params }
  )
    .then((res) => {
      dispatch({
        type: SIGNAL_TOPIC_SUCCESSFUL,
        topicIsSignaled: true,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({
        type: SIGNAL_TOPIC_FAILED,
        topicIsSignaled: false,
      });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const getStudents = (filtered, search) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_STUDENTS_REQUESTED });

  return Api('/topics/find', { params: { search: search, filtered: filtered } })
    .then((res) => {
      dispatch({
        type: GET_STUDENTS_SUCCESSFUL,
        students: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_STUDENTS_FAILED });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetChildrenSubject = ()=>(dispatch) =>{
  dispatch({type : RESET_SUBJECTS});
};

export const getStudent = (studentId) => (dispatch) => {
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_STUDENT_REQUESTED });

  return Api(`/topics/find/${studentId}`, { params: params })
    .then((res) => {
      dispatch({
        type: GET_STUDENT_SUCCESSFUL,
        student: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_STUDENT_FAILED });
      dispatch({
        type: SET_ERROR,
        value: err,
      });
      dispatch({ type: ISNT_LOADING });
    });
};
