import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EnhancedLink from '@components/atoms/enhancedLink';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import fr from '@/lang/fr.js';

import LazyImage from '@components/atoms/lazyImage';
import imgUrl from '@/utils/imgUrl.js';

import Notifications from './Notifications/Notifications';
import Menu from './Menu/index';

import whiteLogo from '@assets/svg/logo-white.svg';

import './index.css';

const Header = ({ pathname, me, isAuthenticated, isLoading }) => {
  const [openMenu, updateOpenMenu] = useState(false);
  const [openAccountAccordion, updateOpenAccountAccordion] = useState(false);
  const [openProfileAccordion, updateOpenProfileAccordion] = useState(false);
  const [isFreeAccount, setIsFreeAccount] = useState(false);
  const [isProspect, setIsProspect] = useState(false);

  const onToggleMenuClick = () => {
    updateOpenMenu(!openMenu);
    updateOpenAccountAccordion(false);
    updateOpenProfileAccordion(false);
  };

  const onToggleAccountAccordionClick = () => {
    updateOpenAccountAccordion(!openAccountAccordion);
  };
  const onToggleProfileAccordionClick = () => {
    updateOpenProfileAccordion(!openProfileAccordion);
  };


  useEffect(() => {
    if (me?.roles) {
      me?.roles.indexOf('ROLE_PROSPECT') > -1 ? setIsProspect(true) : setIsProspect(false);
      me?.roles.indexOf('ROLE_FREE') > -1 ? setIsFreeAccount(true) : setIsFreeAccount(false);
    }
  }, [me]);

  return (
    <React.Fragment>
      {isProspect && (
        <div className='bg-teal-dark pgv-0_75'>
          <div className='row'>
            <div className='column-12 fz-13 fw-600 text-white layout-row flex no-width align-center-center'>
              <span>
                Certaines fonctionnalités sont restreintes car vous êtes
                connecté avec un compte d’essai.
              </span>
            </div>
          </div>
        </div>
      )}
      {isFreeAccount && (
        <div className='bg-teal-dark pgv-0_75'>
          <div className='row'>
            <div className='column-12 fz-13 fw-600 text-white layout-row flex no-width align-center-center'>
              <span>
                Certaines fonctionnalités sont restreintes car vous êtes
                connecté avec un compte gratuit.
              </span>
            </div>
          </div>
        </div>
      )}
      <header className='size-100p bg-primary pgv-0_5'>
        <div className='row sm-align-spacebetween-center md-align-center-center'>
          <div className='column-4 no-width header__profile'>
            <Menu
              me={me}
              isProspect={isProspect}
              isFreeAccount={isFreeAccount}
              onToggleMenuClick={onToggleMenuClick}
              openMenu={openMenu}
              onToggleAccountAccordionClick={onToggleAccountAccordionClick}
              openAccountAccordion={openAccountAccordion}
              onToggleProfileAccordionClick={onToggleProfileAccordionClick}
              openProfileAccordion={openProfileAccordion}
            />
          </div>
          <div className='sm-mgr-auto md-mgr-0 column-4 no-width text-center'>
            <EnhancedLink
              className='text-white h-text-gray-light flex align-center-center'
              activeClassName='text-primary-dark'
              isNavLink={true}
              to='/'
              exact
            >
              <img src={whiteLogo} width={160} height={100} alt='109' className='cef-rounded-none'/>
            </EnhancedLink>
          </div>
          <div className='column-4 no-width flex layout-row align-end-center header__profile'>
            {isAuthenticated && (
              <React.Fragment>
                <Notifications unreadNotifications={me?.unreadNotifications} />
                <div className='flex'>
                  <span className='bg-primary-light round w-5 h-5 mgl-0_5 sm-dp-none md-dp-block'>
                    <LazyImage
                      src={imgUrl({
                        fileName: me?.image ? me?.image.fileName : null,
                        width: '80',
                        height: '80',
                      })}
                      alt={me?.username}
                      width={80}
                      height={80}
                      className='round'
                    />
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  me: PropTypes.object,
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isProspect: PropTypes.bool,
  isFreeAccount: PropTypes.bool,
  pathname: PropTypes.string,
  onToggleMenuClick: PropTypes.func,
  openMenu: PropTypes.bool,
  onToggleAccountAccordionClick: PropTypes.func,
  onToggleProfileAccordionClick: PropTypes.func,
  openAccountAccordion: PropTypes.bool,
  openProfileAccordion: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
    isLoading: state.isLoading,
    isAuthenticated: state.auth?.isAuthenticated,
  };
};

export default withRouter(connect(mapStateToProps, null)(Header));
