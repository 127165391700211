import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Alertbanner = ({
  me,
}) => {

  const [showAlertBanner, updateShowAlertBanner] = useState(true);

  const closeAlertBanner = () => {
    updateShowAlertBanner(!showAlertBanner);
    localStorage.setItem('alertbanner', me?.alert.id.toString());
  };

  const createMarkup = (text) => {
    return {__html: text};
  };

  return (
    <div className={ `alertbanner bg-teal-dark pgv-0_75 ${!showAlertBanner && 'hide'}` }>
      <div className="row">
        <div className="column-12 fz-13 fw-600 text-white layout-row flex no-width align-center-center">
          <span className="mgl-auto" dangerouslySetInnerHTML={createMarkup(me?.alert?.message)}></span>
          <span className="mgl-auto text-white h-text-gray anim-all curp" onClick={ () => closeAlertBanner() }><svg className="w-3 h-3"><use xlinkHref="#icon-close"/></svg></span>
        </div>
      </div>
    </div>
  );
};


Alertbanner.propTypes = {
  me: PropTypes.object,
  closeAlertBanner: PropTypes.func,
  showAlertBanner: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
  };
};

export default connect(mapStateToProps, null)(Alertbanner);

