import React, {Suspense, lazy, useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Header from '@components/molecules/header/Header';
import {connect} from 'react-redux';
import PrivateRoute from './PrivateRoute.js';
import PropTypes from 'prop-types';
import fr from '@/lang/fr.js';
import OnboardingPositioningTest from '@components/molecules/OnboardPositionnement';
import AlertbannerPreregistrationForm from '@components/molecules/AlertbannerPreregistrationForm';
import AlertbannerPreregistrationNotPecCpf from '@components/molecules/AlertbannerPreregistrationNotPecCpf';
import Onboarding from '@components/molecules/Onboarding/Onboarding';
import PositionnementBanner from '@components/molecules/PositionnementBanner';
import ScrollToTop from './ScrollToTop';
const AsyncLogin = lazy(() => import('@/pages/login'));
const AsyncNoMatch = lazy(() => import('@/pages/404'));
const AsyncHome = lazy(() => import('@/pages/home'));
const AsyncLearningIndex = lazy(() => import('@/pages/learning/index'));
const AsyncLearningShow = lazy(() => import('@/pages/learning/show'));
const AsyncHomeworks = lazy(() => import('@/pages/homeworks'));
const AsyncQuestionsGroups = lazy(() => import('@/pages/questionsGroups'));
const AsyncProfile = lazy(() => import('@/pages/profile'));
const AsyncAccount = lazy(() => import('@/pages/account'));
const AsyncVideos = lazy(() => import('@/pages/videos'));
const AsyncAgenda = lazy(() => import('@/pages/agenda'));
const AsyncVideoconferences = lazy(() => import('@/pages/videoconferences'));
const AsyncFaq = lazy(() => import('@/pages/faq'));
const AsyncLogoutVisio = lazy(() => import('@/pages/logoutVisio/logoutVisio'));
const AsyncPositioning = lazy(() => import('@/pages/positioning/positioning'));
const AsyncSchoolReport = lazy(() => import('@/pages/schoolReport/index'));
const AsyncHomeworkPresentation = lazy(() =>
  import('@/pages/HomeworkPresentation/index')
);
const AsyncQuizzPresentation = lazy(() =>
  import('@/pages/learning/quizzPresentation/index')
);
const AsyncHomeworkFreeQuestion = lazy(() =>
  import('@/pages/HomeworkFreeQuestion/index')
);
const AsyncRessources = lazy(() => import('@/pages/ressources'));
const AsyncSuspendedAccount = lazy(() => import('@/pages/suspendedAccount'));
const AsyncStyleguide = lazy(() => import('@/pages/styleguide'));
const AsyncReport = lazy(() => import('@/pages/report'));
const AsyncPaymentSuccess = lazy(() => import('@/pages/payment/success'));
const AsyncPaymentFailure = lazy(() => import('@/pages/payment/failure'));
const AsyncMaintenance = lazy(() => import('@/pages/Maintenance/Maintenance'));
const AsyncPreRegistrationExamination = lazy(() => import('@/pages/preRegistrationExamination'));

const AsyncCoursesV2 = lazy(()=>import('@/V2/pages/learning/learning'));

const Router = ({isAuthenticated, maintenance, state, updateState, pathname, me}) => {

  useEffect(() => {
    (me && (me?.remindersLevel === 4 || me?.remindersLevel > 4)) && updateState({...state, displayModalRelaunch: true});
  }, [me]);

  return (
    <BrowserRouter>
      <ScrollToTop/>
      {isAuthenticated && state.OpenBanner && (
        <PositionnementBanner/>
      )}
      {isAuthenticated && !maintenance && <Header/>}
      {me?.selectedOffer && me?.selectedOffer?.isFirstConnection === true && (
        <Onboarding/>
      )}
      {me.selectedOffer && me.selectedOffer.isFirstConnection === false && <OnboardingPositioningTest
        OpenBanner={state.OpenBanner}
        isAuthenticated={isAuthenticated}
        SetOpenBanner={updateState}
      />}
      {isAuthenticated && !maintenance && pathname !== '/' + fr.route.preregistration_exam && me.selectedOffer?.alertPreRegistrationExam &&
        <AlertbannerPreregistrationForm guidePreRegistrationExam={me.selectedOffer.guidePreRegistrationExam}/>}
      {isAuthenticated && !maintenance && me.selectedOffer?.isPreRegistrationNoCpfOrPec &&
        <AlertbannerPreregistrationNotPecCpf/>}

      <Suspense fallback={null}>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={AsyncHome}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.courses}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId`}
            component={AsyncLearningIndex}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId`}
            component={AsyncLearningShow}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId/${fr.route.videos}`}
            component={AsyncVideos}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId/${fr.route.subchapter}/:subchapterId/${fr.route.question}`}
            component={AsyncQuestionsGroups}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.evaluate}/:courseId`}
            component={AsyncHomeworks}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.evaluate}/:courseId/${fr.route.homework}/:homeworkId`}
            component={AsyncQuestionsGroups}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.revise}/:courseId`}
            component={AsyncQuestionsGroups}
          />
          <Route
            exact
            path={'/questionPreview'}
            component={AsyncQuestionsGroups}
          />

          <PrivateRoute
            exact
            path={`/${fr.route.suspendedAccount}`}
            component={AsyncSuspendedAccount}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.position}`}
            component={AsyncPositioning}
          />
          {isAuthenticated && !maintenance &&
            <PrivateRoute
              path={`/${fr.route.agenda}`}
              component={AsyncAgenda}
            />
          }
          <PrivateRoute
            path={`/${fr.route.videoconferences}`}
            component={AsyncVideoconferences}
          />
          <PrivateRoute
            path={`/${fr.route.profile}`}
            component={AsyncProfile}
          />
          <PrivateRoute
            path={`/${fr.route.account}`}
            component={AsyncAccount}
          />
          <PrivateRoute
            path={`/${fr.route.report}`}
            component={AsyncReport}
          />
          <PrivateRoute
            path={`/${fr.route.schoolReport}`}
            component={AsyncSchoolReport}
          />
          <PrivateRoute
            path={`/${fr.route.evaluate}/:courseId/${fr.route.homework}/:homeworkId/${fr.route.presentation}`}
            component={AsyncHomeworkPresentation}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.learning}/:courseId/${fr.route.chapter}/:chapterId/${fr.route.subchapter}/:subchapterId/${fr.route.presentation}`}
            component={AsyncQuizzPresentation}
          />
          <PrivateRoute
            path={`/${fr.route.evaluate}/:courseId/${
              fr.route.homework
            }/:homeworkId/${'editor'}`}
            component={AsyncHomeworkFreeQuestion}
          />
          <Route
            path={`/${fr.route.payment_success}`}
            component={AsyncPaymentSuccess}
          />
          <Route
            path={`/${fr.route.payment_failure}`}
            component={AsyncPaymentFailure}
          />
          <PrivateRoute path={`/${fr.route.faq}`} component={AsyncFaq}/>
          <PrivateRoute
            path={`/${fr.route.ressources}`}
            component={AsyncRessources}
          />
          <PrivateRoute
            exact
            path='/styleguide'
            component={AsyncStyleguide}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.preregistration_exam}`}
            component={AsyncPreRegistrationExamination}
          />
          {/*---------------------------ROUTES V2---------------------------*/}
          <PrivateRoute
            path={`/${fr.route.homeworkV2}/:homeworkId/${fr.route.presentation}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.homeworkV2}/:homeworkId`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            path={`/${fr.route.homeworkV2}/:homeworkId/${fr.route.doingHomework}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            path={`/${fr.route.homeworkV2}/:homeworkCorrectionId/${fr.route.homeworkCorrection}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.quiz}/:quizzId/${fr.route.presentation}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.quiz}/:quizzId/${fr.route.question}`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.quiz}/:quizzId`}
            component={AsyncCoursesV2}
          />
          <PrivateRoute
            exact
            path={`/${fr.route.questionV2}/:questionId`}
            component={AsyncCoursesV2}
          />
          <Route path="/maintenance" component={AsyncMaintenance}/>
          <Route path="/logoutvisio" component={AsyncLogoutVisio}/>
          <Route path="/login" component={AsyncLogin}/>
          <Route component={AsyncNoMatch}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

Router.propTypes = {
  isAuthenticated: PropTypes.bool,
  maintenance: PropTypes.bool,
  state: PropTypes.object,
  updateState: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    me: state.me,
    isAuthenticated: state.auth?.isAuthenticated,
    maintenance: state.auth?.maintenance,
  };
};
export default connect(mapStateToProps)(Router);
