import Api, { params } from '../modules/Api.js';

import { IS_LOADING, ISNT_LOADING } from './isLoading';
import { SET_ERROR, RESET_ERROR } from './error';
import {POST_QUESTION_FAILED, POST_QUESTION_REQUESTED, POST_QUESTION_SUCCESSFUL} from './questionsGroups';

export const GET_CONTENTS_REQUESTED = 'contentBlocks/GET_CONTENTS_REQUESTED';
export const GET_CONTENTS_SUCCESSFUL = 'contentBlocks/GET_CONTENTS_SUCCESSFUL';
export const GET_CONTENTS_FAILED = 'contentBlocks/GET_CONTENTS_FAILED';

export const GET_BODYCOURSES_REQUESTED = 'contentBlocks/GET_BODYCOURSES_REQUESTED';
export const GET_BODYCOURSES_SUCCESSFUL = 'contentBlocks/GET_BODYCOURSES_SUCCESSFUL';
export const GET_BODYCOURSES_FAILED = 'contentBlocks/GET_BODYCOURSES_FAILED';

export const GET_BODYPAGES_REQUESTED = 'contentBlocks/GET_BODYPAGES_REQUESTED';
export const GET_BODYPAGES_SUCCESSFUL = 'contentBlocks/GET_BODYPAGES_SUCCESSFUL';
export const GET_BODYPAGES_FAILED = 'contentBlocks/GET_BODYPAGES_FAILED';

export const GET_CONTENT_REQUESTED = 'contentBlocks/GET_CONTENT_REQUESTED';
export const GET_CONTENT_SUCCESSFUL = 'contentBlocks/GET_CONTENT_SUCCESSFUL';
export const GET_CONTENT_FAILED = 'contentBlocks/GET_CONTENT_FAILED';

export const SET_BREADCRUMB = 'contentBlocks/SET_BREADCRUMB';
export const RESET_BREADCRUMB = 'contentBlocks/RESET_BREADCRUMB';
export const SET_BREADCRUMBSTEPBACK = 'contentBlocks/SET_BREADCRUMBSTEPBACK';
export const SET_BREADCRUMBLASTITEM = 'contentBlocks/SET_BREADCRUMBLASTITEM';

export const RESET_CONTENT = 'contentBlocks/RESET_CONTENT';
export const RESET_CONTENTS = 'contentBlocks/RESET_CONTENTS';

export const RESET_PAGE = 'contentBlocks/RESET_PAGE';

export const MAKE_DONE_REQUESTED = 'contentBlocks/MAKE_DONE_REQUESTED';
export const MAKE_DONE_SUCCESSFUL = 'contentBlocks/MAKE_DONE_SUCCESSFUL';
export const MAKE_DONE_FAILED = 'contentBlocks/MAKE_DONE_FAILED';

export const MAKE_VIDEO_SEEN_REQUESTED = 'contentBlocks/MAKE_VIDEO_SEEN_REQUESTED';
export const MAKE_VIDEO_SEEN_SUCCESSFUL = 'contentBlocks/MAKE_VIDEO_SEEN_SUCCESSFUL';
export const MAKE_VIDEO_SEEN_FAILED = 'contentBlocks/MAKE_VIDEO_SEEN_FAILED';

export const MAKE_SEND_FILE_ACTIVITY_REQUESTED = 'contentBlocks/MAKE_SEND_FILE_ACTIVITY_REQUESTED';
export const MAKE_SEND_FILE_ACTIVITY_SUCCESSFUL = 'contentBlocks/MAKE_SEND_FILE_ACTIVITY_SUCCESSFUL';
export const MAKE_SEND_FILE_ACTIVITY_FAILED = 'contentBlocks/MAKE_SEND_FILE_ACTIVITY_FAILED';

export const MAKE_REMOVE_FILE_ACTIVITY_REQUESTED = 'contentBlocks/MAKE_REMOVE_FILE_ACTIVITY_REQUESTED';
export const MAKE_REMOVE_FILE_ACTIVITY_SUCCESSFUL = 'contentBlocks/MAKE_REMOVE_FILE_ACTIVITY_SUCCESSFUL';
export const MAKE_REMOVE_FILE_ACTIVITY_FAILED = 'contentBlocks/MAKE_REMOVE_FILE_ACTIVITY_FAILED';

export const ANSWER_ACTIVITY_REQUESTED = 'contentBlocks/ANSWER_ACTIVITY_REQUESTED';
export const ANSWER_ACTIVITY_SUCCESSFUL = 'contentBlocks/ANSWER_ACTIVITY_SUCCESSFUL';
export const ANSWER_ACTIVITY_FAILED = 'contentBlocks/ANSWER_ACTIVITY_FAILED';


const initialState = {
  contentBlock : {},
  bodyCourses : {},
  bodyPages : {},
  breadcrumb : [{ itemSubject: 'Parcours'}],
  content : {}
};

export default function ContentBlock(state = initialState, action) {
  switch (action.type) {
    case GET_CONTENTS_REQUESTED:
      return {
        ...state,
      };
    case GET_CONTENTS_SUCCESSFUL:
      return {
        ...state,
        contentBlock : action.contents
      };
    case GET_CONTENTS_FAILED:
      return {
        ...state,
      };
    case GET_BODYCOURSES_REQUESTED:
      return {
        ...state,
      };
    case GET_BODYCOURSES_SUCCESSFUL:
      return {
        ...state,
        bodyCourses : action.contents
      };
    case GET_BODYCOURSES_FAILED:
      return {
        ...state,
      };
    case GET_BODYPAGES_REQUESTED:
      return {
        ...state,
      };
    case GET_BODYPAGES_SUCCESSFUL:
      return {
        ...state,
        bodyPages : action.contents
      };
    case GET_BODYPAGES_FAILED:
      return {
        ...state,
      };
    case GET_CONTENT_REQUESTED:
      return {
        ...state,
      };
    case GET_CONTENT_SUCCESSFUL:
      return {
        ...state,
        content : action.content
      };
    case GET_CONTENT_FAILED:
      return {
        ...state,
      };
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumb : [...state.breadcrumb, { itemSubject: action.step, pageId :action.pageId}]
      };
    case RESET_BREADCRUMB:
      return {
        ...state,
        breadcrumb : [{ itemSubject: 'Parcours'}]
      };
    case SET_BREADCRUMBLASTITEM:
      return {
        ...state,
        breadcrumb: [...state.breadcrumb.slice(0, (state.breadcrumb.length - 1)),
          {itemSubject: action.step, pageId: action.pageId}]
      };
    case SET_BREADCRUMBSTEPBACK:
      return {
        ...state,
        breadcrumb : [ ...state.breadcrumb.slice(0,(state.breadcrumb.length - action.stepLength))]
      };
    case RESET_CONTENT :
      return {
        ...state,
        bodyCourses : {},
        bodyPages : {},
        content : {}
      };
    case RESET_CONTENTS :
      return {
        ...state,
        content : {}
      };
    case RESET_PAGE :
      return {
        ...state,
        bodyPages : {},
        content : {}
      };
    case MAKE_DONE_REQUESTED :
      return {
        ...state,
      };
    case MAKE_DONE_SUCCESSFUL :
      return {
        ...state,
        bodyPages: {
          ...state.bodyPages,
          contents: state.bodyPages.contents.map(x => ({...x, done: x.id === action.id ? true : x.done}))
        }
      };
    case MAKE_DONE_FAILED :
      return {
        ...state,
      };
    case MAKE_VIDEO_SEEN_SUCCESSFUL :
      return {
        ...state,
      };
    case MAKE_VIDEO_SEEN_FAILED :
      return {
        ...state,
      };
    case MAKE_SEND_FILE_ACTIVITY_SUCCESSFUL :
      return {
        ...state,
        content: {...state.content, userFiles: {...state.content.userFiles, id: action.fileId}}
      };
    case MAKE_VIDEO_SEEN_SUCCESSFUL :
      return {
        ...state,
      };
    case MAKE_VIDEO_SEEN_SUCCESSFUL :
      return {
        ...state,
      };
    case MAKE_VIDEO_SEEN_FAILED :
      return {
        ...state,
      };
    case MAKE_SEND_FILE_ACTIVITY_SUCCESSFUL :
      return {
        ...state,
        content: {...state.content, userFiles: {...state.content.userFiles, id: action.fileId}}
      };
    default:
      return state;
  }
}

export const getParentsContents =()=>(dispatch)=>{
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_CONTENTS_REQUESTED });
  return Api('/content_blocs', { params: params })
    .then((res)=>{
      dispatch({
        type: GET_CONTENTS_SUCCESSFUL,
        contents: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_CONTENTS_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getBodyCourses =(id)=>(dispatch)=>{
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_BODYCOURSES_REQUESTED });
  return Api(`/content_blocs/${id}/contents`, { params: params })
    .then((res)=>{
      dispatch({
        type: GET_BODYCOURSES_SUCCESSFUL,
        contents: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_BODYCOURSES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getBodyPages =(id)=>(dispatch)=>{
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_BODYPAGES_REQUESTED });
  return Api(`/contents/page/${id}`, { params: params })
    .then((res)=>{
      dispatch({
        type: GET_BODYPAGES_SUCCESSFUL,
        contents: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_BODYPAGES_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const getContent = (id, contentType)=>(dispatch)=>{
  dispatch({ type: RESET_ERROR });
  dispatch({ type: IS_LOADING });
  dispatch({ type: GET_CONTENT_REQUESTED });
  return Api(`/contents/${contentType}/${id}`, { params: params })
    .then((res)=>{
      dispatch({
        type: GET_CONTENT_SUCCESSFUL,
        content: res.data,
      });
      dispatch({ type: ISNT_LOADING });
    })
    .catch((err) => {
      dispatch({ type: GET_CONTENT_FAILED });
      if (err?.response?.status !== 401) {
        dispatch({
          type: SET_ERROR,
          value: err,
        });
      }
      dispatch({ type: ISNT_LOADING });
    });
};

export const resetAllContent = ()=>(dispatch)=>{
  dispatch({
    type : RESET_CONTENT,
  });
};

export const resetContents = ()=>(dispatch)=>{
  dispatch({
    type : RESET_CONTENTS,
  });
};

export const resetPage = ()=>(dispatch)=>{
  dispatch({
    type : RESET_PAGE,
  });
};

export const makeDone = (id)=>(dispatch)=>{
  dispatch({ type: MAKE_DONE_REQUESTED });

  return Api.post(`/contents/${id}/done`, {},{ params: params })
    .then((res) => {
      dispatch({
        type: MAKE_DONE_SUCCESSFUL,
        id : id
      });
    }).catch((err) => {
      dispatch({ type: MAKE_DONE_FAILED });
    });
};


//GESTION DU BREADCRUMB
export const setBreadcrumb = (step, pageId)=>(dispatch)=>{
  dispatch({
    type : SET_BREADCRUMB,
    step : step,
    pageId : pageId
  });
};

export const resetBreadcrumb = ()=>(dispatch)=>{
  dispatch({
    type : RESET_BREADCRUMB,
  });
};

export const setBreadcrumbLastItem = (step, pageId)=>(dispatch)=>{
  dispatch({
    type : SET_BREADCRUMBLASTITEM,
    step : step,
    pageId : pageId
  });
};

export const setBreadcrumbStepBack = (stepLength)=>(dispatch)=>{
  dispatch({
    type : SET_BREADCRUMBSTEPBACK,
    stepLength : stepLength,
  });
};

export const makeVideoSeen =  (id)=> (dispatch)=>{
  dispatch({
    type: MAKE_VIDEO_SEEN_REQUESTED
  });
  return Api.post(`/ressources/${id}/seen`,{ params: params })
    .then((res) => {
      dispatch({
        type: MAKE_VIDEO_SEEN_SUCCESSFUL
      });
    }).catch((err) => {
      dispatch({ type: MAKE_VIDEO_SEEN_FAILED });
    });
};


//GESTION DU CONTENT TYPE ACTIVITE
export const sendFileActivity = (id, file)=>(dispatch)=>{
  dispatch({ type: MAKE_SEND_FILE_ACTIVITY_REQUESTED });
  let formData = new FormData();
  let data = {};
  formData.append('document', file);

  return Api.post(`/contents/activity/${id}/files`, formData,{ params: params })
    .then((res) => {
      console.log('dans redux', res);
      dispatch({
        type: MAKE_SEND_FILE_ACTIVITY_SUCCESSFUL,
        fileId : res.data.id
      });
      dispatch({ type: ISNT_LOADING });
      formData.delete('document', file);
    }).catch((err) => {
      dispatch({ type: MAKE_SEND_FILE_ACTIVITY_FAILED });
    });
};

export const removeFileActivity = (id, idFile) => (dispatch) => {
  dispatch({type: MAKE_REMOVE_FILE_ACTIVITY_REQUESTED});

  return Api.delete(`/contents/activity/${id}/files/${idFile}`,{ params: params })
    .then((res) => {
      dispatch({ type: MAKE_REMOVE_FILE_ACTIVITY_SUCCESSFUL });
      dispatch({ type: ISNT_LOADING });
    }).catch((err) => {
      dispatch({ type: MAKE_REMOVE_FILE_ACTIVITY_FAILED });
    });
};


export const answerActivity = (activityId, answer, isDone) =>  (dispatch) => {
  dispatch({type: RESET_ERROR});
  dispatch({type: ANSWER_ACTIVITY_REQUESTED});

  return Api.post( `contents/activity/${activityId}` ,
    {answer: answer, done : isDone},
    {params: params})
    .then((res) => {
      dispatch({
        type: ANSWER_ACTIVITY_SUCCESSFUL,
      });
    })
    .catch((err) => {
      dispatch({type: ANSWER_ACTIVITY_FAILED});
    });
};




